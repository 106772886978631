$(function(){
  $('.slider_wrap').each( function(i) {
    var $_t = $(this);
    $_t.find('.slider_for').addClass('.slider_for' + i).slick( {
      lazyLoad: 'ondemand',// or 'progressive'
      slidesToShow: 8,
      slidesToScroll: 4,
      arrows: true,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 1365,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
        }
      ]
    });
  });
});
$(window).on('load', function(){
});
var currentWidth = window.innerWidth;
window.addEventListener("resize", function() {
  if (currentWidth == window.innerWidth) {
    return;
  }
  currentWidth = window.innerWidth;
});